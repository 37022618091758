import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LBgSect,
  CPageNav,
  CFaqList,
  LContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'FAQ',
            sub: 'よくあるご質問',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/faq/kv.png',
              },
              imgSp: {
                src: '/assets/images/faq/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'よくあるご質問',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap exClass="u_mbExLarge">
          <CPageNav
            data={[
              {
                label: 'ご宿泊',
                link: {
                  href: '/faq/',
                },
              },
              {
                label: 'レストラン＆バー',
                link: {
                  href: '/faq/restaurants/',
                },
              },
              {
                label: 'ウエディング',
                link: {
                  href: '/faq/wedding/',
                },
              },
              {
                label: 'ご宴会・会議',
                link: {
                  href: '/faq/banquet/',
                },
              },
              {
                label: '館内施設',
                link: {
                  href: '/faq/facility/',
                },
              },
              {
                label: 'アクセス',
                link: {
                  href: '/faq/access/',
                },
              },
            ]}
          />
          <CFaqList
            hash=""
            data={[
              {
                q: '御殿場プレミアム・アウトレット®へのアクセスはどのようになりますか?',
                a: (
                  <>
                    ホテルより直行バス（完全予約制）が運行しております。
                    <br />
                    詳しくは
                    <Link to="/about/access/bus/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: '横浜・八景島シーパラダイスへのアクセスはどのようになりますか?',
                a: (
                  <>
                    ホテルより直行バス（完全予約制）が運行しております。
                    <br />
                    詳しくは
                    <Link to="/about/access/bus/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
              {
                q: '羽田空港と成田空港へのアクセスはどのようになりますか?',
                a: (
                  <>
                    ホテルより直行バス（完全予約制）が運行しております。
                    <br />
                    詳しくは
                    <Link to="/about/access/bus/">こちら</Link>
                    をご覧ください。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LContact
        exClass="u_telUnit_end"
        tel={[
          {
            title: '代表',
            number: '045-221-1111',
          },
        ]}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
